//General display

.pvtAxisContainer {
  display: flex;
  flex-wrap: wrap;
  border: none !important;
}

table.pvtUi, table.pvtTable {
  border: 1px solid $gray-300;
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden; /* hide standard table (collapsed) border */
  box-shadow: 0 0 0 1px $gray-300; /* this draws the table border  */ 
}


//Border of the table

table.pvtUi tr, table.pvtUi td, table.pvtTable th {
  border: 1px solid var(--bs-gray-300);
}


//Table styles

table.pvtTable th {
  background-color: $light;
}

table.pvtUi li {
  margin-right: 1rem;
  list-style-type: none;
}

.pvtRenderer, .pvtAggregator {
  -webkit-appearance: listbox !important;
  border-color: $gray-300 !important;
}

.pvtTriangle {
  display: none;
}

.pvtTable {
  td, th {
    padding: 5px;
  }

  th {
    border: 1px solid white !important;
  }
}

.pvtColLabel, .pvtAxisLabel, .pvtRowLabel, .pvtTotalLabel  {
  font-weight: 600;
  border: 1px solid white !important;
}


//Sort arrow styles

.pvtRowOrder, .pvtColOrder {
  font-size: 2rem;
  color: $info;
}