/* custom.scss */
@font-face {
  font-family: "Fluent Emoji";
  src: url("../fonts/fluentmj.ttf");
}

@font-face {
  font-family: "Robertson";
  src: url("../fonts/Robertson-Alternate.ttf");
}

body {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  padding-right: 0px !important;
  color: var(--bs-gray-900);
  font-family: -apple-system-headline, -apple-system-subheadline,
    -apple-system-short-headline, -apple-system-short-subheadline,
    -apple-system-body, -apple-system-short-body, -apple-system-tall-body,
    -apple-system-caption1, -apple-system-caption2, -apple-system-short-caption1,
    -apple-system-footnote, -apple-system-short-footnote, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
    "Fluent Emoji", "Robertson", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}
.app-header .btn-icon i,
.header-search i {
  font-size: 17px;
}
.app-header .symbol:hover {
  opacity: 0.75 !important;
}
.header .nav-item {
  font-weight: normal;
}
.header .nav-line-tabs .nav-item .nav-link.active,
.header .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
  border-bottom: none !important;
}
.header {
  z-index: 1050;
  position: fixed;
  top: 0;
  width: 81vw;
  height: auto;
}

@media screen and (max-width: 992px) {
  .app-header {
    z-index: 1040;
    position: fixed;
    top: 0;
    width: 100vw;
    height: auto;
  }

  .page.internal {
    padding-top: 80px !important;
  }

  .clear-btn {
    top: 1px !important;
    left: 99% !important;
    width: 21px !important;
    height: 21px !important;
  }
}

.page.internal {
  padding-top: 12px;
  padding-bottom: 100px;
}
.page.internal.container-fluid {
    --bs-gutter-x: 3rem;
}
.detail.container {
    --bs-gutter-x: 3rem;
}
.page.home {
  padding-left: 15px;
  padding-right: 15px;
}
.modal .page.internal {
  padding-top: 0px !important;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-gray-900);
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none;
}

.time-unit-dropdown-toggle::after {
    display: inline-block;
    color: var(--bs-gray-700) !important;
}

.nav {
  flex-wrap: nowrap;
}
.record .default-tabs .tab-content {
  min-height: 800px;
}

.h-210px {
  height: 210px;
}

.timeline .timeline-line {
  border-left-color: #bbb;
}

.app-sidebar-user:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.btn-group-toggle:hover {
  background-color: var(--bs-gray-300);
}

:root {
  --bs-app-sidebar-width: 250px !important;
  --bs-app-header-height: 66px !important;
  --bs-gap: .75rem;
}

[data-bs-theme="light"] {
    --bs-light: #F0F4FB !important;
    --bs-light-active: #ECF1FA !important;

    --bs-secondary: #dee6f6 !important;
    // #dbe4ed
    // #e2e6f2
    --bs-secondary-active: #d4dcec !important;
    --bs-secondary-inverse: var(--bs-gray-800) !important;
}

.btn-secondary {
    i {
        color: var(--bs-gray-800);
    }
}

[data-bs-theme="light"] {
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23211F1C'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23211F1C'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .shadow-hover-lg{
      &:hover{
          @extend .shadow-hover;
      }
  }

  .vis-grid.vis-vertical,
  .vis-grid.vis-minor,
  .vis-grid.vis-major {
    border-left-color: var(--bs-gray-300);
  }

  .border-light-info {
    border-color: #b9bbf0 !important;
  }
}

[data-bs-theme="dark"] {
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .shadow-hover-lg{
    &:hover{
        box-shadow: inset 0 0 0 1000px rgba(255,255,255,.025) !important;
    }
  }

  .vis-grid.vis-vertical,
  .vis-grid.vis-minor,
  .vis-grid.vis-major {
    border-left-color: var(--bs-gray-400);
  }

  .border-light-info {
    border-color: var(--bs-info) !important;
  }
  .skyish-dark {
   background-color: #0094b6 !important;
   color: #F0F4FB !important;
  }
}

$aqua: #0094b6;
// $skyish: #CBE9FB;
$skyish: #B9E2FA;
$magenta: #d84280;
$indigo: #9370db;
$gray: #666;
$lime: #3e9623;
$brown: #795548;
$pine: #004d40;
$midnight: #2c3e50;
$pomegranate: #c0392b;
$carrot: #e67e22;

$transition-link: color 0.2s ease, background-color 0.2s ease !default;

$new-theme-colors: (
  "aqua": $aqua,
  "skyish": $skyish,
  "magenta": $magenta,
  "indigo": $indigo,
  "lime": $lime,
  "brown": $brown,
  "pine": $pine,
  "gray": $gray,
  "midnight": $midnight,
  "pomegranate": $pomegranate,
  "carrot": $carrot,
) !default;

// Theme colors
$old-theme-colors: (
  "white": $white,
  // custom color type
  "light": $light,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
) !default;

@each $name, $value in $old-theme-colors {
  .badge-outline-#{$name} {
    color: $value;
    border: 1px solid $value;
  }
  .btn-#{$name}.end-opacity-gradient:not(:hover) {
    background: linear-gradient(
      to right,
      transparent 5%,
      map-get($old-theme-colors, $name)
    );
  }
  .btn-#{$name}.start-opacity-gradient:not(:hover) {
    background: linear-gradient(
      to left,
      transparent 5%,
      map-get($old-theme-colors, $name)
    );
  }
}

@each $name, $value in $new-theme-colors {
  :root {
    --bs-#{$name}: #{$value};
  }
  // btn
  .btn.btn-#{$name} {
    box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
    $color: $white !important;
    $icon-color: $white !important;
    $border-color: $value;
    $bg-color: $value;
    $color-active: theme-inverse-color($name);
    $icon-color-active: theme-inverse-color($name);
    $border-color-active: theme-active-color($name);
    $bg-color-active: theme-active-color($name);
    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
  .btn-#{$name} {
    box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
    $color: $white !important;
    $icon-color: $white !important;
    $border-color: $value;
    $bg-color: $value;
    $color-active: theme-inverse-color($name);
    $icon-color-active: theme-inverse-color($name);
    $border-color-active: theme-active-color($name);
    $bg-color-active: theme-active-color($name);
    @include button-custom-variant(
      $color,
      $icon-color,
      $border-color,
      $bg-color,
      $color-active,
      $icon-color-active,
      $border-color-active,
      $bg-color-active
    );
  }
  .btn.btn-#{$name}:hover {
    background-color: darken($value, 10%) !important;
    box-shadow: inset 0 0 0 1px darken($value, 7.5%) !important;
    color: $white !important;
  }
  .btn-light-#{$name} {
    color: $value;
    background-color: lighten($value, 55%);
  }
  //badge
  .badge-#{$name} {
    color: $white;
    background-color: $value;
  }
  .badge-outline-#{$name} {
    color: $value;
    border: 1px solid $value;
  }

  .badge-light-#{$name} {
    color: $value;
    background-color: lighten($value, 55%) !important;
  }
  @if ($name == "magenta" or $name == "purple") {
    .badge-light-#{$name} {
      color: $value;
      background-color: lighten($value, 30%) !important;
    }
  }
  //text
  .text-#{$name} {
    color: $value;
  }
  .text-hover-#{$name} {
    transition: $transition-link;

    i {
      transition: $transition-link;
    }

    &:hover,
    &:active {
      transition: $transition-link;
      color: $value !important;

      i {
        transition: $transition-link;
        color: $value !important;
      }
    }
  }
  //border
  .border-#{$name} {
    border-color: $value !important;
  }
  .border-hover-#{$name}:hover {
    border-color: $value !important;
  }

  .border-active-#{$name}.active {
    border-color: $value !important;
  }
  //bg
  .bg-#{$name} {
    --bg-color: #{red($value)}, #{green($value)}, #{blue($value)};
    background-color: $value !important;

    &.hoverable:hover {
      background-color: darken($value, 10%) !important;
    }
  }

  .bg-hover-#{$name} {
    cursor: pointer;

    &:hover {
      --bg-color: #{red($value)}, #{green($value)}, #{blue($value)};
      background-color: $value !important;
    }
  }

  .bg-active-#{$name} {
    &.active {
      --bg-color: #{red($value)}, #{green($value)}, #{blue($value)};
      background-color: $value !important;
    }
  }
  .bg-state-#{$name} {
    cursor: pointer;
    &.active,
    &:hover {
      --bg-color: #{red($value)}, #{green($value)}, #{blue($value)};
      background-color: $value !important;
    }
  }
}

.dropdown-item {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: inherit;
}
.roles .ng-option-selected span,
.dimensions .ng-option-selected span,
.users .ng-option-selected span {
  //   color: rgba(0,0,0,0.65) !important;
  font-weight: 500 !important;
}
.roles .ng-option,
.dimensions .ng-option,
.users .ng-option,
.choices .ng-option {
  display: flex !important;
  align-items: center;
  height: 38px;
  padding: 10px 10px !important;
  margin-block: 4px;
}
.roles .ng-option.ng-option-marked,
.dimensions .ng-option.ng-option-marked,
.users .ng-option.ng-option-marked,
.choices .ng-option.ng-option-marked {
  //   color: rgba(0,0,0,0.65) !important;
  background-color: rgb(248, 246, 242) !important;
}

.roles .ng-option span:before,
.dimensions .ng-option span:before,
.users .ng-option span:after,
.choices .ng-option .check:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  display: inline-block;
  color: var(--bs-info);
  opacity: 0;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  right: 20px;
}
.roles .ng-option-selected span:before,
.dimensions .ng-option-selected span:before,
.users .ng-option-selected span:after,
.choices .ng-option-selected .check:before {
  opacity: 1;
}
.choices .ng-option-selected span {
  font-weight: 500 !important;
}

// nav-link in administration
.nav-line-tabs {
  border-bottom-color: $gray-300 !important;
  // Active & Hover States
  .nav-item .nav-link.active,
  .nav-item.show .nav-link, {
    border-bottom: 1px solid var(--bs-info) !important;
  }
  .nav-item .nav-link:hover:not(.disabled) {
    border-bottom: 1px solid var(--bs-gray-600) !important;
  }
  // 2x Line
  &.nav-line-tabs-2x {
    border-bottom-width: 2px !important;

    .nav-item {
      margin-bottom: -2px !important;

      .nav-link {
        border-bottom-width: 2px !important;
      }
    }

    // Active & Hover States
    .nav-item .nav-link.active,
    .nav-item.show .nav-link,
    .nav-item .nav-link:hover:not(.disabled) {
      border-bottom-width: 2px !important;
    }
  }
}

// sider menu
.menu-state-bg
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here) {
  background-color: transparent !important;
}

.menu-state-bg
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here) {
  // background-color: transparent !important;
}
.app-header {
  height: 80px;
}
ngb-highlight.menu-link {
  display: inline-block !important;
}
.fw-semibold {
  font-weight: 500 !important;
}

.menu-link.active {
  background-color: var(--bs-info-light) !important;
}

.app-sidebar-toolbar .menu,
ordering .menu,
groupby .menu {
  max-height: calc(70vh - 90px) !important;
  overflow-y: scroll !important;
}

.app-sidebar-toolbar .form-select {
  background-image: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.mobile-spaces-accordion {
  max-height: calc(100vh - 400px);
  overflow: auto;
}

.accordion-body .menu-sub {
  display: block !important;
}

.app-sidebar-menu .accordion-body {
  padding: 0 !important;
}

.app-sidebar-menu .accordion-item {
  border: none !important;
}
.app-sidebar-menu .accordion-button {
  color: var(--bs-text-gray-700) !important;
  font-weight: 500 !important;
  padding: 0.675rem 0.5rem;

  &::after {
    color: var(--bs-text-gray-700);
  }
}
.app-sidebar-menu .accordion-body .menu-sub .menu-item .menu-link:hover {
  color: var(--bs-app-light-sidebar-menu-link-color-hover) !important;
}
.app-sidebar-menu .accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.app-sidebar-menu {
  max-height: calc(90vh - 100px) !important;
  overflow-y: scroll !important;
}

.home .accordion-body {
  padding: 0 !important;
}

.home .accordion-item {
  @extend .shadow-sm;
  @extend .rounded;
  background-color: var(--bs-app-light-sidebar-bg-color);
  margin-bottom: 1.25rem !important;
  border: none !important;
}
.home .accordion-header {
  color: var(--bs-gray-700);
  font-weight: 500 !important;
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-app-light-sidebar-bg-color);
  @extend .rounded;
  &::after {
    color: var(--bs-gray-700);
  }
}

.app-sidebar .accordion-body,
.app-sidebar-toolbar .accordion-body {
  @extend .rounded;
  padding: 0 0rem !important;
  padding-left: 0.5rem !important;
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.app-sidebar-toolbar .accordion-body {
  background-color: var(--bs-light) !important;
}

.app-sidebar .accordion-body .accordion-body {
  overflow: hidden;
  max-height: 100%;
}
.app-sidebar-toolbar .form-check-input:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
  background-color: var(--bs-info) !important;
}

.app-sidebar .accordion-item,
.app-sidebar-toolbar .accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.app-sidebar-toolbar .accordion-item {
  // background-color: var(--bs-gray-100) !important;
  background-color: transparent !important;
}

.app-sidebar .accordion-button,
.app-sidebar-toolbar .accordion-button {
  color: var(--bs-text-gray-700);
  font-weight: 500 !important;
  padding: 1.1rem !important;
  // @extend .bg-white;
  background-color: var(--bs-app-light-sidebar-bg-color);
  &::after {
    color: var(--bs-text-gray-700);
  }
  &:hover {
    color: var(--bs-info);
    .form-check-label {
        @extend .text-gray-800
    }
  }
}

.app-sidebar-toolbar .accordion-button {
  background-color: transparent !important;
}

.hide-accordion-arrow {
  .accordion-button::after {
    display: none;
  }
}

.spaces-menu {
  #spaces-menu-header > .accordion-button {
    background-color: var(--bs-app-light-sidebar-bg-color);
    padding: 0 !important;

    &::after {
      display: none;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  margin-block: 4px;
}

#kt_app_header_container {
  background-color: var(--bs-app-light-sidebar-bg-color);
}

.datepicker,
ngb-datepicker {

  font-size: 1.1rem;
  text-wrap: nowrap;
  font-weight: 500;
  color: var(--bs-gray-700) !important;

  background-color: var(--bs-body-bg) !important;
  border-color: var(--bs-gray-200) !important;

  .form-select {
    font-size: 1.4rem !important;
    font-weight: 600 !important;
    border: none !important;
    color: var(--bs-gray-800) !important;
    cursor: pointer;
    text-align: center;
    @extend .bg-hover-light;
  }

  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background: transparent !important;
  }

  .ngb-dp-month {
    width: 100% !important;
  }

  .ng-untouched.ng-pristine {
    border-color: var(--bs-gray-200) !important;
  }
  .ngb-dp-header {
    background-color: transparent !important;
    @extend .w-100;
    @extend .px-2;
  }

  .ngb-dp-week,
  .ngb-dp-week.ngb-dp-weekdays {
    background-color: transparent !important;
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .w-100;
    border: none !important;
    text-transform: capitalize;

    .ngb-dp-weekday {
      font-size: 1.2rem !important;
      font-weight: 600 !important;
      color: var(--bs-gray-800) !important;
    }
  }

  .ngb-dp-arrow-btn {
    .ngb-dp-navigation-chevron {
        border-width: 0.15em 0.15em 0 0 !important;
        width: 0.6em !important;
        height: 0.6em !important;
        color: var(--bs-gray-800);
    }
  }

  .ngb-dp-day.ngb-dp-today {
    span {
        font-size: inherit !important;
        font-weight: bold;
        width: 3rem !important;
        height: 3rem !important;
        @extend .badge;
        @extend .badge-light-info;
        @extend .badge-circle;
    }
  }
  ngb-datepicker-month {
    @extend .d-flex;
    @extend .flex-column;
    @extend .gap-1;
    padding-top: 1rem !important;
  }
}

.ng-select.ng-select-multiple .ng-select-container {
  background-color: var(--bs-input-solid-bg) !important;
  border-color: var(--bs-input-solid-bg) !important;
}

.ng-select.ng-select-multiple .ng-select-container input {
  color: var(--bs-gray-800) !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: transparent !important;
  border: transparent !important;
}
.ng-select-container.ng-has-value {
  padding-right: 3px !important;
  padding-left: 3px !important;
}
.field .users.ng-select .ng-select-container .ng-value-container,
.field .choices.ng-select .ng-select-container .ng-value-container {
  padding-left: 0px !important;
  display: flex !important;
  flex-wrap: wrap;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  color: var(--bs-gray-800) !important;
  margin-block: 4px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--bs-gray-800) !important;
  background-color: transparent !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: var(--bs-info-light) !important;
}

.ng-dropdown-panel.ng-select-multiple.ng-select-bottom {
  background-color: transparent !important;
}

.ng-dropdown-panel.ng-select-top {
  background-color: transparent !important;
}

.dropdown-item.menu-link.no-hover:hover {
    background-color: transparent !important;
    color: var(--bs-gray-600) !important;
}

.administration-tab::-webkit-scrollbar-thumb {
    background-color: var(--bs-gray-300);
}

.shadow-sm {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px !important
}

.pan-element {
    @extend .px-2;

    .blocks {
        .block.h-100 {
            @extend .pb-4;
        }
    }
    max-width: -webkit-fill-available;
    min-width: -webkit-fill-available;
}




.vis-foreground > .vis-group:first-child {
    @extend .mt-1;
}



.transform-none {
    transform: none !important;
}

.clear-btn {
    top: 2px;
    left: 98%;
    width: 16px !important;
    height: 16px !important;
}

.cal-month-view .cal-event {
    width: 12px !important;
    height: 12px !important;
}

body:has(.vis-timeline) {
    .page.internal {
        padding-bottom: 0 !important;
    }
}

.detail.page  a.dropdown-toggle {
    margin-left: 0 !important;
}

[data-bs-theme="dark"] {
  .list-group {
    --bs-list-group-color: var(--bs-dark-text-emphasis);
    --bs-list-group-bg: var(--bs-dark-bg-subtle);
    --bs-list-group-border-color: var(--bs-dark-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
    --bs-list-group-active-color: var(--bs-dark-bg-subtle);
    --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
  }
}

[data-bs-theme="light"] {
  .list-group {
   --bs-list-group-color: var(--bs-light-text-emphasis);
    --bs-list-group-bg: var(--bs-light-bg-subtle);
    --bs-list-group-border-color: var(--bs-light-border-subtle);
    --bs-list-group-action-hover-color: var(--bs-emphasis-color);
    --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
    --bs-list-group-action-active-color: var(--bs-emphasis-color);
    --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
    --bs-list-group-active-color: var(--bs-light-bg-subtle);
    --bs-list-group-active-bg: var(--bs-light-text-emphasis);
    --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
  }
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(79,85,218, .25) !important;
}

.btn-xs {
    height: 30px !important;
    width: 25px !important;
}
