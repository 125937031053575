.truncate-2l{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .truncate-1l{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .record .card .thumbnail{
    object-fit: cover;
  }

.shadow-hover-xs {
    &:hover{
        box-shadow: $box-shadow-xs !important;
    }
}
.shadow-hover-sm {
    &:hover{
        box-shadow: $box-shadow-sm !important;
    }
}
.shadow-hover-lg{
    &:hover{
        box-shadow: $box-shadow-lg !important;
    }
}
.shadow-hover {
    &:hover{
        box-shadow: $box-shadow !important;
    }
}

.rounded-top-start {
    border-radius: 0rem;
    border-top-left-radius: 0.95rem !important;
}
.rounded-top-end {
    border-radius: 0rem;
    border-top-right-radius: 0.95rem !important;
}
.rounded-bottom-start {
    border-radius: 0rem;
    border-bottom-left-radius: 0.95rem !important;
}
.rounded-bottom-end {
    border-radius: 0rem;
    border-bottom-right-radius: 0.95rem !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
}

.cursor-auto {
  cursor: auto !important;
}
