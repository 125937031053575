//
// Vis Timeline
//

@import '~vis-timeline/styles/vis-timeline-graph2d.css';

// Variables
$vis-border-color: var(--bs-border-color);
$vis-border-dashed-color: var(--bs-border-dashed-color);

// General
.vis-timeline {
    overflow: visible;
    // max-height: calc(100vh - 225px) !important;
    // min-height: unset !important;
    border: none !important;

    @if ($enable-rounded) {
        border-radius: $border-radius !important;
    }

    .vis-labelset {
        .vis-label {
            display: flex;
            align-items: center;
            padding-left: 1rem;
            padding-right: 1rem;
            border-bottom: none;
            font-size: $h4-font-size;
            font-weight: $font-weight-bold;
            color: $gray-900;
        }
    }

    .vis-itemset {
    }

    .vis-foreground {
        .vis-group {
            border-bottom: none;
        }
    }

    .vis-item {
        z-index: 99;
        position: absolute;
        color: $gray-700;
        border-color: $primary;
        border-width: 1px;
        background-color: var(--bs-gray-100);

        @if ($enable-rounded) {
            border-radius: $border-radius !important;
        }
        /*
        &.vis-selected {
            background-color: $warning-light;
            color: $gray-700;
            border-color: $warning;
        }
        */
        .vis-item-content:has(div > div.btn) {
            padding: 0;
            width: 100%;
            transform: none !important;
        }
        .vis-item-content {
            padding: 0.75rem 1rem;
            width: 100%;
            transform: none !important;
        }
        .vis-item-content > div > .btn {
            height: 45px !important;
        }
        .vis-item-overflow {
            overflow: visible;
        }
    }

    .vis-time-axis {
        font-size: $font-size-sm;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        .vis-text {
            font-size: small;
            color: var(--bs-gray-600);
        }

        .vis-text.vis-major > div {
            font-weight: 500 !important;
            transform: translateY(-5px);
        }

        .vis-grid.vis-minor {
            border-left-color: var(--bs-border-dashed-color) !important;
        }

        .vis-grid.vis-vertical.vis-minor {
            border-left-style: solid !important;
        }
        .vis-grid.vis-vertical.vis-major {
            border-left-style: dashed !important;
        }
    }

    .vis-panel {
        .vis-shadow {
            box-shadow: none !important;
        }

        .vis-center {
            border-left: none;
            border-right: none;
            border-bottom: none;
            top: 60px !important;
        }
    }

  

    .vis-panel {
        &.vis-bottom,
        &.vis-center,
        &.vis-left,
        &.vis-right,
        &.vis-top {
            border-color: var(--bs-gray-200) !important;
        }
    }

    .vis-panel {
        &.vis-top {
            border-radius: $border-radius;
            border-left-style: none;
            border-right-style: none;
            border-bottom-left-radius: 0%;
            border-bottom-right-radius: 0%;
            border-bottom: 1px solid $vis-border-color !important;
            box-shadow: 0 4px 7px -7px black;
            position: sticky !important;
            top: 41px !important;
            padding: 5px 0;
        }
      &.vis-left {
        padding-top: 10px;
        padding-bottom: 10px;
        display: none;
      }
    }

    @media (max-width: 991.98px) {
        .vis-panel {
            &.vis-top {
                height: 60px;
                padding-top: 12px;
                top: 148px !important;
            }
        }
    }

    .vis-panel {
        &.vis-background {
            width: calc(100% + 2px) !important;
        }
    }

    .vis-label.vis-nested-group {
        background-color: var(--bs-app-light-sidebar-bg-color);
    }
    .vis-label.vis-nesting-group.expanded {
        background-color: var(--bs-gray-100);
    }
    .vis-label.vis-nesting-group.expanded:before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: "\f078";
      font-size: 16px;
      color: var(--bs-gray-800) !important;
    }
    .vis-label.vis-nesting-group.collapsed:before{
      font-family: "Font Awesome 6 Pro";
      font-weight: 300;
      content: "\f054";
      font-size: 16px;
      color: var(--bs-gray-800) !important;
    }

    .vis-label.vis-nested-group .vis-inner {
      padding-left: 10px !important;
    }

    .vis-current-time {
        z-index: 1;
        background-color: var(--bs-danger);
        width: 1px;
        top: 56px !important;

        &:after {
            content: "";
            position: absolute;
            left: -4px;
            top: 0;
            width: 9px;
            height: 9px;
            background: var(--bs-danger);
            border-radius: 100%;
        }

        &:before {
            content: "";
            position: absolute;
            left: -40px;
            top: 4px;
            width: 40px;
            height: 100%;
            opacity: .08;
            background-image: linear-gradient(to right, rgba(255,0,0,0), var(--bs-danger)) !important;
        }
      }
}

.vis-timeline {
    .vis-time-axis {

        div.vis-vertical[class$="vis-odd"]:not(div[class*="vis-day"], .vis-friday, .vis-saturday, .vis-sunday, .vis-monday, .vis-tuesday, .vis-wednesday, .vis-thursday), 
        .vis-grid.vis-vertical.vis-minor.vis-tuesday,
        .vis-grid.vis-vertical.vis-minor.vis-thursday,
        .vis-grid.vis-vertical.vis-major.vis-tuesday.vis-day1,
        .vis-grid.vis-vertical.vis-major.vis-thursday.vis-day1 {
            background-color: var(--bs-gray-100);
        }

        .vis-grid.vis-vertical.vis-major.vis-week1 {
            // To distinguish the first week of the year.
            border-left: 1px dashed $warning !important;
        }

        .vis-grid.vis-vertical.vis-minor.vis-saturday,
        .vis-grid.vis-vertical.vis-major.vis-saturday,
        .vis-grid.vis-vertical.vis-minor.vis-sunday,
        .vis-grid.vis-vertical.vis-major.vis-sunday {
            background-color: var(--bs-gray-200) !important;
        }
 
        .vis-grid.vis-vertical.vis-minor.vis-saturday,
        .vis-grid.vis-vertical.vis-major.vis-saturday {
            border-right: 1px solid var(--bs-app-light-sidebar-bg-color) !important;
        }
    }
}

.has-groupby .vis-panel.vis-left {
  padding-top: 0 !important;
  display: block !important;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 10px !important;
  @extend .border-end;
}

body:not(:has(.has-groupby)) {
    .vis-panel.vis-left {
        display: block !important;
        width: 6px !important;
    }

    .vis-panel.vis-background.vis-vertical {
        left: 0 !important;
    }
    
    .vis-timeline .vis-panel.vis-top {
        left: 0 !important;
        width: 100% !important;
    }

    .vis-timeline .vis-panel.vis-center {
        left: 0 !important;
    }
}

.has-groupby .vis-panel.vis-background {
  width: inherit !important;
}

.vis-panel.vis-background.vis-vertical {
    border-radius: 0 !important;
}
// Custom style to make the timeline more compliant with the theme

.vis-timeline-custom {
    .vis-timeline {
        border: 0 !important;

        .vis-label {
            padding-left: 0 !important;
        }
    }

    .vis-panel {
        &.vis-bottom,
        &.vis-center,
        &.vis-left,
        &.vis-right,
        &.vis-top {
            border: 0 !important;
        }
    }

    .vis-item {
        background-color: transparent;
        border: 0 !important;
        border-radius: 0 !important;

        .vis-item-content {
            padding: 0 !important;
        }
    }
}


//Style for the custom popover (not being used)

.timeline-popover {
    z-index: 100;
    width: 400px;
    background-color: white;
    border: 1px solid $gray-300;
    border-radius: 2.5%;
    position: absolute;
}


//Makes the cursor a pointer instead of a crosshair when hovering over timeline items

.vis-item .vis-drag-center {
    cursor: pointer
}


//Makes the cursor a grabbing hand instead of a crosshair when dragging timeline items around

.vis-item .vis-drag-center:active {
    cursor: grabbing
}
