//
// Custom Variables
//

/////////////
// General //
/////////////
    
// Main
$app-dark-mode-body-bg-color: #151521;

// Header base
$app-header-base-height: 96px;
$app-header-base-height-mobile: 70px;
$app-header-base-border-when-toolbar-enabled: $border-dashed-color;
$app-header-base-border-when-toolbar-enabled-dark: $border-dashed-color-dark;

// Header primary
$app-header-primary-base-height: 80px;

// Header secondary
$app-header-secondary-base-height: 67px;
$app-header-secondary-base-bg-color: var(--#{$prefix}body-bg);
$app-header-secondary-sticky-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-header-secondary-sticky-box-shadow-dark: none;

// Toolbar base
$app-toolbar-base-height: auto;
$app-toolbar-base-height-mobile: auto;
$app-toolbar-sticky-height: 60px;
$app-toolbar-sticky-bg-color: $white;
$app-toolbar-sticky-bg-color-dark: darken(#1e1e2d, 2%);
$app-toolbar-sticky-bg-color-mobile: $app-toolbar-base-bg-color;
$app-toolbar-sticky-bg-color-mobile-dark: $app-toolbar-base-bg-color-dark;
$app-toolbar-sticky-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-toolbar-sticky-box-shadow-dark: none;
$app-toolbar-sticky-box-shadow-mobile: $app-toolbar-base-box-shadow;
$app-toolbar-sticky-box-shadow-mobile-dark: $app-toolbar-base-box-shadow-dark;

// Content
$app-content-padding-y: 25px;
$app-content-padding-y-mobile: 15px;

// Sidebar base
$app-sidebar-base-width: 300px;
$app-sidebar-base-width-mobile: 250px; 

// Sidebar menu
$app-sidebar-menu-link-padding-x: 14px;
$app-sidebar-menu-indention: 15px;

// Sidebar logo icon
$app-sidebar-logo-icon-custom-hover-color: $primary;
$app-sidebar-logo-icon-custom-hover-color-dark: $primary;

// Footer
$app-footer-height: auto;
$app-footer-height-mobile: auto;

// Scrolltop
$scrolltop-bottom: 42px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;


/////////////
// Layouts //
/////////////

// Light sidebar
$app-light-sidebar-page-bg-color: #F2F5FB;
$app-light-sidebar-page-bg-color-dark: $app-dark-mode-body-bg-color;

$app-light-sidebar-bg-color: $body-bg;
$app-light-sidebar-bg-color-dark: $body-bg-dark;

$app-light-sidebar-logo-icon-custom-color: $gray-400;
$app-light-sidebar-logo-icon-custom-color-dark: $gray-400-dark;

$app-light-sidebar-scrollbar-color: darken($gray-100, 4%);
$app-light-sidebar-scrollbar-color-dark: $gray-300-dark;

$app-light-sidebar-separator-color: #E0E0DE;
$app-light-sidebar-separator-color-dark: #2D2D43;

$app-light-sidebar-btn-custom-color: $gray-500;
$app-light-sidebar-btn-custom-color-dark: $gray-700-dark;
$app-light-sidebar-btn-custom-bg-color: #F1F6F9;
$app-light-sidebar-btn-custom-bg-color-dark: $gray-300-dark;
$app-light-sidebar-btn-custom-bg-color-active: darken(#F1F6F9, 2%);
$app-light-sidebar-btn-custom-bg-color-active-dark: $gray-200-dark;

$app-light-sidebar-select-custom-color: #9FA6AA;
$app-light-sidebar-select-custom-color-dark: $gray-600-dark;
$app-light-sidebar-select-custom-bg-color: #F4F6F8;
$app-light-sidebar-select-custom-bg-color-dark: $gray-300-dark;

$app-light-sidebar-menu-heading-color: $gray-400;
$app-light-sidebar-menu-heading-color-dark: $gray-400-dark;

$app-light-sidebar-menu-root-link-color:  $gray-700;
$app-light-sidebar-menu-root-link-color-dark:  $gray-700-dark;
$app-light-sidebar-menu-root-icon-color:  $gray-400;
$app-light-sidebar-menu-root-icon-color-dark:  $gray-400-dark;
$app-light-sidebar-menu-root-bullet-color: $gray-300;
$app-light-sidebar-menu-root-bullet-color-dark: $gray-300-dark;
$app-light-sidebar-menu-root-arrow-color: $gray-500;
$app-light-sidebar-menu-root-arrow-color-dark: $gray-500-dark;

$app-light-sidebar-menu-root-link-color-hover:  $gray-800;
$app-light-sidebar-menu-root-link-color-hover-dark:  $gray-800-dark;
$app-light-sidebar-menu-root-icon-color-hover:  $gray-500;
$app-light-sidebar-menu-root-icon-color-hover-dark:  $gray-500-dark;
$app-light-sidebar-menu-root-bullet-color-hover: $gray-400;
$app-light-sidebar-menu-root-bullet-color-hover-dark: $gray-400-dark;
$app-light-sidebar-menu-root-arrow-color-hover: $gray-600;
$app-light-sidebar-menu-root-arrow-color-hover-dark: $gray-600-dark;

$app-light-sidebar-menu-link-color:  $gray-700;
$app-light-sidebar-menu-link-color-dark:  $gray-700-dark;
$app-light-sidebar-menu-icon-color:  $gray-500;
$app-light-sidebar-menu-icon-color-dark:  $gray-500-dark;
$app-light-sidebar-menu-bullet-color: $gray-300;
$app-light-sidebar-menu-bullet-color-dark: $gray-300-dark;
$app-light-sidebar-menu-arrow-color: $gray-500;
$app-light-sidebar-menu-arrow-color-dark: $gray-500-dark;

$app-light-sidebar-menu-link-color-hover: $gray-800;
$app-light-sidebar-menu-link-color-hover-dark: $gray-800-dark;
$app-light-sidebar-menu-icon-color-hover:  $gray-500;
$app-light-sidebar-menu-icon-color-hover-dark:  $gray-500-dark;
$app-light-sidebar-menu-bullet-color-hover: $gray-400;
$app-light-sidebar-menu-bullet-color-hover-dark: $gray-400-dark;
$app-light-sidebar-menu-arrow-color-hover: $gray-600;
$app-light-sidebar-menu-arrow-color-hover-dark: $gray-600-dark;

$app-light-sidebar-header-search-icon-color: $gray-700;
$app-light-sidebar-header-search-icon-color-dark: $gray-700-dark;
$app-light-sidebar-header-search-input-color: $gray-700;
$app-light-sidebar-header-search-input-color-dark: $gray-700-dark;
$app-light-sidebar-header-search-input-border-color: #DBE5EB;
$app-light-sidebar-header-search-input-border-color-dark: $gray-300-dark;
$app-light-sidebar-header-search-input-placeholder-color: $gray-400;
$app-light-sidebar-header-search-input-placeholder-color-dark: $gray-400-dark;

$app-light-sidebar-header-navbar-btn-custom-color: $gray-700;
$app-light-sidebar-header-navbar-btn-custom-color-dark: $gray-700-dark;
$app-light-sidebar-header-navbar-btn-custom-icon-color: $gray-700;
$app-light-sidebar-header-navbar-btn-custom-icon-color-dark: $gray-700-dark;
$app-light-sidebar-header-navbar-btn-custom-border-color: #DBE5EB;
$app-light-sidebar-header-navbar-btn-custom-border-color-dark: $gray-200-dark;

// Dark sidebar
$app-dark-sidebar-page-bg-color: #F9FCFE;
$app-dark-sidebar-page-bg-color-dark: $app-dark-mode-body-bg-color;

$app-dark-sidebar-logo-icon-custom-color: #747496;

$app-dark-sidebar-bg-color: #1E1E2D;
$app-dark-sidebar-bg-color-dark: $body-bg-dark;

$app-dark-sidebar-avatar-name-color:#B6B9C8;
$app-dark-sidebar-avatar-description-color:#5D6170;
$app-dark-sidebar-avatar-name-color-hover: $primary;
$app-dark-sidebar-avatar-icon-custom-color: #67677B;

$app-dark-sidebar-scrollbar-color: rgba($white, 0.1);
$app-dark-sidebar-scrollbar-color-dark: $gray-300-dark;

$app-dark-sidebar-separator-color: #383845;
$app-dark-sidebar-separator-color-dark: #2D2D43;

$app-dark-sidebar-btn-custom-color: #747496;
$app-dark-sidebar-btn-custom-color-dark: $gray-700-dark;
$app-dark-sidebar-btn-custom-color-active: $white; 
$app-dark-sidebar-btn-custom-bg-color: #343447;
$app-dark-sidebar-btn-custom-bg-color-dark: $gray-300-dark;
$app-dark-sidebar-btn-custom-bg-color-active: $danger;
$app-dark-sidebar-btn-custom-bg-color-active-dark: $gray-200-dark;

$app-dark-sidebar-select-custom-color: #5D6170;
$app-dark-sidebar-select-custom-color-dark: $gray-600-dark;
$app-dark-sidebar-select-custom-bg-color: rgba(#2E2E3F, 0.6);
$app-dark-sidebar-select-custom-bg-color-dark: $gray-300-dark; 

$app-dark-sidebar-menu-heading-color: darken(#B6B9C8, 15%);

$app-dark-sidebar-menu-root-link-color:  #B6B9C8;
$app-dark-sidebar-menu-root-icon-color:  $gray-600;
$app-dark-sidebar-menu-root-bullet-color: $gray-600;
$app-dark-sidebar-menu-root-arrow-color: $gray-600;

$app-dark-sidebar-menu-root-link-color-hover: lighten(#B6B9C8, 8%);  
$app-dark-sidebar-menu-root-icon-color-hover:  lighten($gray-600, 8%);  
$app-dark-sidebar-menu-root-bullet-color-hover: lighten($gray-600, 8%);  
$app-dark-sidebar-menu-root-arrow-color-hover: lighten($gray-600, 8%);  

$app-dark-sidebar-menu-link-color: #B6B9C8;
$app-dark-sidebar-menu-icon-color: #57576B;
$app-dark-sidebar-menu-bullet-color: #505465;
$app-dark-sidebar-menu-arrow-color: #57576B;

$app-dark-sidebar-menu-link-sub-title-color: #7D8192;

$app-dark-sidebar-menu-link-color-hover: lighten(#B6B9C8, 8%);  
$app-dark-sidebar-menu-icon-color-hover:  lighten(#57576B, 8%);  
$app-dark-sidebar-menu-bullet-color-hover: lighten(#505465, 8%);  
$app-dark-sidebar-menu-arrow-color-hover: lighten(#57576B, 8%);  

$app-dark-sidebar-header-search-icon-color: $gray-700;
$app-dark-sidebar-header-search-input-color: $gray-700;
$app-dark-sidebar-header-search-input-border-color: #EFE9E1;
$app-dark-sidebar-header-search-input-border-color-dark: $gray-300-dark;
$app-dark-sidebar-header-search-input-placeholder-color: #5D6170;

$app-dark-sidebar-header-navbar-btn-custom-color: $gray-700;
$app-dark-sidebar-header-navbar-btn-custom-icon-color: $gray-700;
$app-dark-sidebar-header-navbar-btn-custom-border-color: #EFE9E1;
$app-dark-sidebar-header-navbar-btn-custom-border-color-dark: $gray-200-dark;

// Light header
$app-light-header-page-bg-color: #F9FCFE;
$app-light-header-page-bg-color-dark: $app-dark-mode-body-bg-color;

$app-light-header-page-card-bg-color: $body-bg;
$app-light-header-page-card-bg-color-dark: $body-bg-dark;
$app-light-header-page-card-border-color: #E8E5DD;
$app-light-header-page-card-border-color-dark: $border-color-dark;

$app-light-header-toolbar-bg-color: $app-light-header-page-bg-color;
$app-light-header-toolbar-bg-color-dark: $app-light-header-page-bg-color-dark;

$app-light-header-bg-color: $body-bg;
$app-light-header-bg-color-dark: $body-bg-dark;

$app-light-header-border-color: #F3F3F3;
$app-light-header-border-color-dark: $gray-200-dark;

$app-light-header-btn-custom-color: $gray-600; 
$app-light-header-btn-custom-color-dark: $gray-600-dark; 
$app-light-header-btn-custom-bg-color: $gray-100;
$app-light-header-btn-custom-bg-color-dark: transparent;
$app-light-header-btn-custom-border-color: $gray-100;
$app-light-header-btn-custom-border-color-dark: $gray-300-dark;

$app-light-header-search-icon-color: $gray-700;
$app-light-header-search-icon-color-dark: $gray-700-dark;
$app-light-header-search-input-color: $gray-700;
$app-light-header-search-input-color-dark: $gray-700-dark;
$app-light-header-search-input-border-color: $gray-100;
$app-light-header-search-input-border-color-dark: $gray-300-dark;
$app-light-header-search-input-bg-color: $gray-100;
$app-light-header-search-input-bg-color-dark: transparent;
$app-light-header-search-input-placeholder-color: $gray-400;
$app-light-header-search-input-placeholder-color-dark: $gray-400-dark;

$app-light-header-menu-link-bg-color-active: $gray-100;
$app-light-header-menu-link-bg-color-active-dark: $gray-100-dark;
$app-light-header-menu-link-color: $gray-600;
$app-light-header-menu-link-color-dark: $gray-600-dark;
$app-light-header-menu-icon-color:  $gray-500;
$app-light-header-menu-icon-color-dark:  $gray-500-dark;
$app-light-header-menu-bullet-color: $gray-500;
$app-light-header-menu-bullet-color-dark: $gray-500-dark;
$app-light-header-menu-arrow-color: $gray-500;
$app-light-header-menu-arrow-color-dark: $gray-500-dark;

$app-light-header-menu-link-color-hover:  $gray-800;
$app-light-header-menu-link-color-hover-dark:  $gray-800-dark;
$app-light-header-menu-icon-color-hover:  $gray-600;
$app-light-header-menu-icon-color-hover-dark:  $gray-600-dark;
$app-light-header-menu-bullet-color-hover: $gray-600;
$app-light-header-menu-bullet-color-hover-dark: $gray-600-dark;
$app-light-header-menu-arrow-color-hover: $gray-600;
$app-light-header-menu-arrow-color-hover-dark: $gray-600-dark;

$app-light-header-menu-bullet-color-active: $gray-800;
$app-light-header-menu-bullet-color-active-dark: $gray-800-dark;


// Dark header
$app-dark-header-page-bg-color: #FBFBFB;
$app-dark-header-page-bg-color-dark: $app-dark-mode-body-bg-color;
$app-dark-header-page-card-bg-color: $body-bg;
$app-dark-header-page-card-bg-color-dark: $body-bg-dark;
$app-dark-header-page-card-border-color: #E8E5DD;
$app-dark-header-page-card-border-color-dark: $border-color-dark;

$app-dark-header-bg-color: #161514;

$app-dark-header-toolbar-bg-color: $app-dark-header-page-bg-color;

$app-dark-header-border-color: darken(#43475A, 10%);
$app-dark-header-border-color-dark: $gray-100-dark;

$app-dark-header-toolbar-bg-color: $app-light-header-page-bg-color;
$app-dark-header-toolbar-bg-color-dark: $app-light-header-page-bg-color-dark;

$app-dark-header-btn-custom-color: $gray-500; 
$app-dark-header-btn-custom-bg-color: transparent;
$app-dark-header-btn-custom-bg-color-dark: transparent;
$app-dark-header-btn-custom-border-color: #43475A; 

$app-dark-header-search-icon-color: $gray-500;  
$app-dark-header-search-input-color: $gray-700;
$app-dark-header-search-input-border-color: #43475A;
$app-dark-header-search-input-border-color-dark: $gray-300-dark;
$app-dark-header-search-input-bg-color: #161514;
$app-dark-header-search-input-bg-color-dark: $gray-300-dark;
$app-dark-header-search-input-placeholder-color: $gray-700;

$app-dark-header-menu-link-bg-color-active: $danger;
$app-dark-header-menu-link-bg-color-active-dark: $gray-100-dark;
$app-dark-header-menu-link-color: #7D8192;
$app-dark-header-menu-icon-color:  $gray-500;
$app-dark-header-menu-bullet-color: $gray-500;
$app-dark-header-menu-arrow-color: $gray-500;

$app-dark-header-menu-link-color-hover:  $white;
$app-dark-header-menu-icon-color-hover:  $gray-600;
$app-dark-header-menu-bullet-color-hover: $gray-600;
$app-dark-header-menu-arrow-color-hover: $gray-600;

// Mini sidebar
$app-mini-sidebar-page-bg-color: #F9FCFE;
$app-mini-sidebar-page-bg-color-dark: $app-dark-mode-body-bg-color;

$app-mini-sidebar-menu-bg-color:#1E1E2D;
$app-mini-sidebar-menu-bg-color-dark: $body-bg-dark;
$app-mini-sidebar-menu-icon-color:#9494a9;
$app-mini-sidebar-menu-icon-color-active: $danger;

$app-mini-sidebar-menu-link-bg-color:#353545;

$app-mini-sidebar-header-search-icon-color: $gray-700;
$app-mini-sidebar-header-search-input-color: $gray-700;
$app-mini-sidebar-header-search-input-border-color: #DBE5EB;
$app-mini-sidebar-header-search-input-border-color-dark: $gray-300-dark;
$app-mini-sidebar-header-search-input-bg-color: #F6F5F2;
$app-mini-sidebar-header-search-input-bg-color-dark: $gray-300-dark;
$app-mini-sidebar-header-search-input-placeholder-color: $gray-400; 

$app-mini-sidebar-header-btn-custom-color: $gray-700; 
$app-mini-sidebar-header-btn-custom-icon-color: $gray-700; 
$app-mini-sidebar-header-btn-custom-border-color: #EFE9E1;
$app-mini-sidebar-header-btn-custom-border-color-dark: $gray-300-dark;

$app-mini-sidebar-header-btn-custom-color-active: $primary; 
$app-mini-sidebar-header-btn-custom-icon-color-active: $primary; 
$app-mini-sidebar-header-btn-custom-border-color-active: #DBE5EB;
$app-mini-sidebar-header-btn-custom-border-color-active-dark: $gray-300-dark;
$app-mini-sidebar-header-btn-custom-bg-color-active: $light;

// Overlay
$app-overlay-page-bg-color: #FBFBFB;
$app-overlay-page-bg-color-dark: $app-dark-mode-body-bg-color;

$app-overlay-page-card-bg-color: $body-bg;
$app-overlay-page-card-bg-color-dark: $body-bg-dark;

$app-overlay-page-card-border-color: #E8E5DD;
$app-overlay-page-card-border-color-dark: $border-color-dark;

$app-overlay-header-bg-color: $gray-900;

$app-overlay-sidebar-bg-color: $body-bg;
$app-overlay-sidebar-bg-color-dark: $body-bg-dark;
$app-overlay-sidebar-menu-shadow-color: rgba(197, 196, 211, 0.08);
$app-overlay-sidebar-menu-shadow-color-dark: none;

$app-overlay-sidebar-menu-icon-color: $gray-400;
$app-overlay-sidebar-menu-icon-color-dark: $gray-700-dark;
$app-overlay-sidebar-menu-icon-color-active: $danger;
$app-overlay-sidebar-menu-link-bg-color: $gray-100;
$app-overlay-sidebar-menu-link-bg-color-dark: $gray-200-dark;

$app-overlay-search-icon-color: $gray-500;
$app-overlay-search-input-color: $gray-700;
$app-overlay-search-input-border-color: #43475A;
$app-overlay-search-input-placeholder-color: $gray-700;

$app-overlay-btn-custom-border-color: #43475A;
$app-overlay-btn-custom-color: $gray-500;  
$app-overlay-btn-custom-color-active: $primary; 

$app-overlay-page-title-heading-color: $white;
$app-overlay-page-title-desc-color: #7E8299;
$app-overlay-page-title-breadcrumb-color: $app-overlay-page-title-desc-color;
$app-overlay-page-title-breadcrumb-color-hover: $app-overlay-page-title-heading-color;
$app-overlay-page-title-breadcrumb-bullet-color: $app-overlay-page-title-desc-color;