//
// Sidebar
//

[data-kt-app-layout="mini-sidebar"] {
    --#{$prefix}app-sidebar-width: 100px;
     
    .app-sidebar {
        background-color: var(--#{$prefix}app-mini-sidebar-menu-bg-color);         

        .menu {
            > .menu-item {
                width: 100%;

                @include menu-link-default-state( 
                    $title-color: null,                     
                    $icon-color: $app-mini-sidebar-menu-icon-color, 
                    $bullet-color: null, 
                    $arrow-color: null,                 
                    $bg-color: null, 
                    $all-links: false
                );

                @include menu-link-hover-state(   
                    $title-color: null,                  
                    $icon-color: $app-mini-sidebar-menu-icon-color-active,
                    $bullet-color: null, 
                    $arrow-color: null,                      
                    $bg-color: transparent, 
                    $all-links: false
                );

                @include menu-link-here-state( 
                    $title-color: null,                  
                    $icon-color: $app-mini-sidebar-menu-icon-color-active,   
                    $bullet-color: null, 
                    $arrow-color: null,                   
                    $bg-color: $app-mini-sidebar-menu-link-bg-color !important, 
                    $all-links: false
                );

                @include menu-link-show-state(  
                    $title-color: null,                    
                    $icon-color: $app-mini-sidebar-menu-icon-color-active,
                    $bullet-color: null, 
                    $arrow-color: null,                      
                    $bg-color: transparent, 
                    $all-links: false
                );

                @include menu-link-active-state( 
                    $title-color: null,                  
                    $icon-color: $app-mini-sidebar-menu-icon-color-active,   
                    $bullet-color: null, 
                    $arrow-color: null,                 
                    $bg-color: $app-mini-sidebar-menu-link-bg-color !important, 
                    $all-links: false
                );
            }             
            
            > .menu-item {
                > .menu-link {                    
                    @include border-radius($border-radius);
                    margin-left: auto;
                    margin-right: auto;
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}