@media print {

  @include color-mode(dark) {
    body {
      background-color: $gray-100 !important;	
    }
    .text-gray-800 {
      color: $gray-800 !important;
    }
    .text-gray-700 {
      color: $gray-700 !important;
    }
    .card {
      background-color: $body-bg;
      border: 1px $card-border-style $card-custom-border-color;
    }
    .badge {
      &.badge-light-info {
        color: $info !important;
        background-color: $info-light;
      }
      
      &.badge-light-danger {
        color: $danger !important;
        background-color: $danger-light;
      }
    }
    .page.record {
      .tabs .nav-tabs {
        box-shadow: inset 0 -3px 0 0 $gray-300 !important;
      }
    }
  }

  a[href] {
    text-decoration: none !important;
  }

  @page {
    size: A3 !important;
    margin: 0.5cm 0.25cm;
  }

  loader {
    display: none !important;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  //détail page
  body:has(ngb-modal-window) {
    display: none;
  }

  widget canvas {
    width: 100% !important;
  }

  .block {
    height: auto !important;
  }

  .tab-pane {
    .block {
        padding: 15px !important;
    }
  }

  .modal-content {
    z-index: 99999;
    .detail {
      padding: 0cm 0.2cm !important;
    }
  }

  .offcanvas-backdrop {
    display: none;
  }

  .detail {
    padding: 0cm 0.8cm;
    margin: 0;
    max-width: 100% !important;
    .card-record {
      display: block !important;
    }
    .card-parent {
      page-break-inside: avoid;
      display: block;
    }
  }

  .admin-checkpoint-image {
    display: contents !important;
    margin-bottom: 9.750px !important;
     img {
       width: unset !important;
       height: unset !important;
       max-height: 200px !important;
       max-width: 200px !important;
     }
  }
  .comment {
    margin-top: 9.750px;
  }
  .d-print-flex-wrap {
    flex-wrap: wrap !important;
  }
  .records,
  .records * {
    visibility: visible;
    .card-record {
      display: block !important;
    }
    .card-parent {
      page-break-inside: avoid;
      display: block;
      margin-bottom: 9.75px;

      .card {
        height: auto !important;
      }

      .record {
        height: auto !important;
      }
    }
  }

  .card {
    .flex-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-grow-1.flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .d-none.d-sm-inline,
    .d-none.d-md-inline,
    .d-none.d-lg-inline,
    .d-none.d-xl-inline {
      display: inline !important;
    }
    .d-none.d-sm-inline-block,
    .d-none.d-md-inline-block,
    .d-none.d-lg-inline-block,
    .d-none.d-xl-inline-block {
      display: inline-block !important;
    }
    .d-none.d-sm-block,
    .d-none.d-md-block,
    .d-none.d-lg-block,
    .d-none.d-xl-block {
      display: block !important;
    }
    .d-none.d-sm-table,
    .d-none.d-md-table,
    .d-none.d-lg-table,
    .d-none.d-xl-table {
      display: table !important;
    }
    .d-none.d-sm-table-row,
    .d-none.d-md-table-row,
    .d-none.d-lg-table-row,
    .d-none.d-xl-table-row {
      display: table-row !important;
    }
    .d-none.d-sm-table-cell,
    .d-none.d-md-table-cell,
    .d-none.d-lg-table-cell,
    .d-none.d-xl-table-cell {
      display: table-cell !important;
    }
    .d-none.d-sm-flex,
    .d-none.d-md-flex,
    .d-none.d-lg-flex,
    .d-none.d-xl-flex {
      display: flex !important;
    }
    .d-none.d-sm-inline-flex,
    .d-none.d-md-inline-flex,
    .d-none.d-lg-inline-flex,
    .d-none.d-xl-inline-flex {
      display: inline-flex !important;
    }
  }

  .grid {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
    gap: 0;
  }

  .col-print-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-print-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-print-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-print-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-print-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-print-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-print-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .file-attachment {
    max-height: 100%;
  }
}
